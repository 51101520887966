.personalForm h3,
.employeForm h3 {
  margin-bottom: 1rem;
}

.staffForm_container {
  background-color: #f6f5f5;
  padding: 2rem 0.5rem;
  border-radius: 10px;
  box-shadow: 0px 0px 7px 0px #00000040;
}

.inputDiv1 {
  display: flex;
  align-items: center;
  border: 1px solid var(--light-text);
  border-radius: 10px;
  padding: 0.5rem;
  background-color: var(--primary-bg);
  height: 45px;
}

.curser_Pointer {
  cursor: pointer;
}

.curser_Pointer input {
  cursor: pointer;
}

.addresMatching input {
  margin-left: 0.6rem;
  height: 1.3rem;
  width: 1.6rem;
}

.inputDiv1 img {
  width: 1.7rem;
}

.inputDiv1 input {
  width: 100%;
  height: 35px;
  border: none;
  outline: none;
}

.input_field input {
  padding: 0.7rem;
  outline: 1px solid #666666;
}

.no_prsnl_id {
  font-size: 15px;
  padding-left: 1rem;
  margin-bottom: 0.3rem;
  color: var(--light-text);
  float: left;
}

.no_prsnl_id label {
  font-size: 10px;
  font-weight: 400;
}

.imgDiv {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}

.imgDiv img {
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.inputDiv1 select {
  width: 100%;
  border: none;
  height: 35px;
  background-color: var(--primary-bg);
  outline: none;
  cursor: pointer;
}

.input_field_custom2 {
  font-size: 14px;
  border: 1px solid var(--light-text);
  padding: 0.5rem;
  border-radius: 6px;
  width: 100%;
  padding-left: 45px;
  height: 40px;
  background-color: var(--primary-bg);
}

.input_field_custom3 {
  font-size: 14px;
  border: 1px solid var(--light-text);
  padding: 0.5rem;
  border-radius: 6px;
  width: 100%;
  height: 50px;
  background-color: var(--primary-bg);
}

.input_field_custom4 {
  display: flex;
  font-size: 14px;
  background-color: var(--secondary-color) !important;
  box-shadow: 0px 1px 3px 0px #00000033;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  position: relative;
  padding-left: 45px;
  height: 40px;
}

.noteMsg {
  font-size: 11px;
  padding-left: 1rem;
}

.inpContainer {
  margin-bottom: 1rem;
}

.inputDiv1 textarea {
  width: 100%;
  border: none;
  outline: none;
}

textarea {
  resize: none;
  height: 100px !important;
}

.saveFormBtns {
  margin: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.saveFormBtns button {
  border: none;
  padding: 0.6rem 1.5rem;
  width: 130px;
  border-radius: 6px;
}

.saveFormBtns .btnCancel {
  color: var(--light-text);
  background-color: var(--light-bg);
}

.saveFormBtns .btnSave {
  color: var(--white-text);
  background-color: var(--blue-bg);
}

.labelCheckbox {
  font-size: 12px;
}

.imginputContainer {
  height: 90%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgInput {
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 50%;
}

.imgInput p {
  font-size: 14px;
}

.imgValidationalert p {
  font-size: 10px;
  margin-bottom: 2px;
  align-items: center;
  color: var(--error-text);
}

.imgInptImg {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

/* -------------------------------------------View Staff Styles------------------------------------------- */

.labelView {
  color: var(--light-text);
  font-size: 14px;
  padding-left: 0.5rem;
}

.inputDiv2 {
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1rem;
  border-radius: 6px;
  position: relative;
  box-shadow: 0px 1px 3px 0px #00000033;
  height: 40px;
}

.empoyeeButtn {
  margin-top: 2rem;
}

.bottomAlgin {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0rem;
}

.centerAlgin {
  display: flex;
  align-items: center;
}

.centerAlginHorizontal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centerAlgin .inputDiv2 {
  width: 100%;
}

.inputDiv2 img {
  width: 1rem;
}

.inputDiv2 input {
  border: none;
  background-color: transparent;
  width: 100%;
  outline: none;
}

.labelPointer {
  cursor: pointer;
}

.inputDiv2 select {
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
  cursor: pointer;
}

.inputDiv2 .btnSearch {
  background-color: var(--grey-bg);
  color: var(--white-text);
  padding: 0.3rem 1rem;
  border: none;
  border-radius: 6px;
  position: absolute;
  right: 6px;
  top: 3px;
}

.btnSearchNew {
  background-color: var(--grey-bg);
  color: var(--white-text);
  padding: 0.3rem 1rem;
  border: none;
  border-radius: 6px;
  height: 40px;
}

.btnAddStaff {
  background-color: var(--primary-color);
  color: var(--white-text);
  border: none;
  border-radius: 10px;
  padding: 0.5rem;
  width: 100%;
}

.viewStaff_table_head {
  margin-bottom: 1rem;
  color: var(--light-black);
}

.prsnlDetails {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-right: 1px solid var(--blue-grey);
  padding: 1.5rem 0.5rem;
}

.prsnlDetails img {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}

.prsnlDetailsText .workingEmp {
  background-color: var(--lightGreen-bg);
  width: fit-content;
  padding: 0.2rem 0.5rem;
  border-radius: 6px;
  margin-bottom: 5px;
  color: var(--secondary-text);
}

.prsnlDetailsText .non_workingEmp {
  background-color: var(--lightRed-bg);
  width: fit-content;
  padding: 0.2rem 0.5rem;
  border-radius: 6px;
  margin-bottom: 5px;
  color: var(--white-text);
}

.prsnlDetailsText p {
  margin: 0;
  color: var(--secondary-text);
  font-size: 15px;
}

.empDetailsText p {
  font-size: 16px !important;
  margin-bottom: 0.5rem;
}

.viewStaff_table_item {
  margin-top: 0.7rem;
  background-color: var(--primary-bg);
  box-shadow: 0px 0.9953445196151733px 6.967411518096924px 0px #00000040;
  border-radius: 10px;
}

.prsnlDetailsSocial ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
}

.prsnlDetailsSocial ul li {
  cursor: pointer;
}

.prsnlDetailsSocial ul img {
  width: 1.5rem;
}

.on-off-toggle {
  width: 58px;
  height: 25px;
  position: relative;
  display: inline-block;
}

.on-off-toggle__slider {
  width: 58px;
  height: 25px;
  display: block;
  border-radius: 34px;
  background-color: #d8d8d8;
  transition: background-color 0.4s;
  cursor: pointer;
}

.on-off-toggle__slider:before {
  content: "";
  display: block;
  background-color: #fff;
  box-shadow: 0 0 0 1px #949494;
  bottom: 2px;
  height: 21px;
  left: 3px;
  position: absolute;
  transition: 0.4s;
  width: 21px;
  z-index: 5;
  border-radius: 100%;
}

.on-off-toggle__slider:after {
  display: block;
  line-height: 24px;
  text-transform: uppercase;
  font-size: 5px;
  font-weight: bold;
  content: "Deactive";
  color: var(--light-text);
  padding-left: 26px;
  transition: all 0.4s;
  bottom: 2px;
  position: absolute;
}

.on-off-toggle__input {
  position: absolute;
  opacity: 0;
}

.on-off-toggle__input:checked + .on-off-toggle__slider {
  background-color: var(--lightBlue-bg);
}

.on-off-toggle__input:checked + .on-off-toggle__slider:before {
  transform: translateX(31px);
}

.on-off-toggle__input:checked + .on-off-toggle__slider:after {
  content: "Active";
  color: #ffffff;
  padding-left: 8px;
}

.actionsSections {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.actionsSections img {
  width: 1.5rem;
}

.sendEmailBtn {
  display: flex;
  align-items: center;
  border: none;
  gap: 0.3rem;
  padding: 0.3rem 1rem;
  border-radius: 6px;
  box-shadow: 0px 0.9953445196151733px 6.967411518096924px 0px #00000040;
  background-color: var(--primary-bg);
  margin-top: 1rem;
}

.sendEmailBtn p {
  margin: 0;
  color: var(--secondary-text);
  font-size: 15px;
}

.sendEmailBtn img {
  width: 1.5rem;
}

.editStaffBtn {
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
}

.confirmModal .modal-content {
  border-radius: 20px;
}

.confirmModal .confirmModalHeader {
  border: none;
}

.confirmModal .confirmModalFooter {
  border: none;
  justify-content: center;
}

.confirmModalFooter {
  display: flex;
  gap: 10px;
}

.confirmAeBtn,
.confirmDeBtn,
.Confirm_Deactive,
.closeConfirmBtn {
  width: 120px;
  border: none;
}

.confirmDeBtn {
  background-color: var(--lightBlack-bg);
  border: none;
}

.confirmAeBtn {
  background-color: var(--lightBlack-bg);
  border-color: var(--lightBlack-bg);
}

.confirmDeBtn:hover {
  background-color: var(--lightRed-bg);
  border: 1px solid var(--secondary-text);
  color: var(--secondary-color);
  border: none;
}

.confirmAeBtn:hover {
  background-color: var(--lightGreen-bg);
  border: 1px solid var(--secondary-text);
  color: var(--secondary-text);
}

.closeConfirmBtn {
  color: var(--secondary-color);
  border-color: var(--secondary-text);
  background-color: var(--light-bg) !important;
  color: var(--light-black);
}

.closeConfirmBtn:hover {
  background-color: var(--lightBlack-bg) !important;
  border: 1px solid var(--secondary-text);
  color: var(--secondary-color);
  border: none;
}

.Confirm_Deactive:hover {
  background-color: var(--primary-color);
}

.closeConfirmAeBtn:hover {
  background-color: var(--lightRed-bg);
  border: 1px solid var(--secondary-text) !important;
  color: var(--white-text) !important;
}

.confirmBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confirmBody img {
  width: 4rem;
}

.confirmBody .modalText {
  color: var(--secondary-text);
  margin: 0;
  text-align: center;
}

.modalTextRed {
  color: var(--red-text);
  margin: 0;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .prsnlDetails {
    gap: 0.5rem;
  }

  .editstaff_container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    border-top: 1px solid var(--blue-grey);
    width: 100%;
    padding: 1rem 0;
  }

  .paddingZero {
    padding: 0;
  }

  .sendEmailBtn {
    margin-top: 0;
  }
}

.disabledIcon {
  cursor: not-allowed;
}

/* -------------------------------------------------Guest View Css---------------------------------------------- */

.sendEmailBtnGuest img {
  width: 1.5rem;
}

@media screen and (max-width: 991px) {
  .sendEmailBtnGuest img {
    width: 1rem;
  }

  .sendEmailBtn p {
    font-size: 12px;
  }

  .guest_incenter {
    font-size: 13px;
  }
}

.sendEmailBtnGuest {
  margin: auto !important;
}

.theadStyle {
  padding: 0.5rem;
}

.tbodyStyle1 {
  padding: 0 0.5rem;
  color: var(--light-text);
}

.tbodyStyle {
  color: var(--light-text);
}

.viewGuest_table_container .table > :not(caption) > * > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: none !important;
  /* text-align: center; */
}

.viewGuest_table_container tbody tr {
  border-radius: 10px;
}

.borderLeftRadius {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.borderRightRadius {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.viewGuest_table_container .table {
  width: 100%;
  border-collapse: collapse;
}

.theadStyle {
  color: var(--light-black);
  font-weight: 500;
}

.tboday .th1,
.theadStyle .th1 {
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}

.tboday .th2,
.theadStyle .th2 {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}

.theadStyle {
  position: sticky;
  top: 0;
  z-index: 1;
}

.tboday {
  overflow-y: auto;
  max-height: 400px;
}

.tableRow {
  box-shadow: 0px 0.9953445196151733px 6.967411518096924px 0px #00000040;
}

.imgThead {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 0.2rem;
}

.imgThead img {
  width: 1rem;
}

.hidden {
  display: none !important;
}

.guest_incenter {
  /* text-align: center; */
  /* height: 42px; */
  display: flex;
  align-items: center;
  /* justify-content: start; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 15ch;
}

.discoverMore_container h6 {
}

.height40px {
  height: 40px;
}

.viewGuest_table_container thead tr th:first-child div {
  padding-left: 1rem !important;
}

.viewGuest_table_container tbody tr td:first-child div {
  padding-left: 1rem !important;
}

.guest_incenter2 {
  /* text-align: center; */
  display: flow;
  align-items: center;
  /* justify-content: center; */
}

.dropDownn {
  position: relative;
  padding-left: 2rem;
}

.dropDownn img {
  height: 13px;
  width: 13px;
  position: absolute;
  top: 40px;
  right: 10px;
  z-index: 1;
}

@media screen and (max-width: 991px) {
  .personalForm h3,
  .employeForm h3 {
    margin-top: 10px;
  }
}

.markVip {
  background-color: var(--light-bg);
  border: none;
  border-radius: 20px;
  padding: 0.2rem 0;
  margin: auto;
  width: 95px;
}

.markVip:hover {
  background-color: var(--primary-color);
}

.markVip:hover p {
  color: var(--white-text) !important;
}

.markVip p {
  margin: 0;
  font-size: 13px;
  color: var(--light-text);
  font-weight: 500;
  padding-top: 0.1rem;
}

.vipMarked {
  background-color: var(--green-bg) !important;
}

.vipMarkedred {
  background-color: var(--error-text) !important;
}

.vipMarked p {
  color: var(--white-text);
}

.vipMarkedred p {
  color: var(--white-text);
}

.tabledata {
  align-content: center;
}

.chooseImgD {
  width: 200px;
  max-width: 100%;
  height: 200px;
  margin-bottom: 0.5rem;
}

.flexColum {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flexGap {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.flexGap a {
  width: 100%;
}

.shadowOnlyBottom p {
  margin: 0;
}

/* ----------------------------------Profile settings Styles------------------------------------- */

@media screen and (min-width: 991px) {
  .heightBefore991 {
    height: 88%;
  }
}

.max_height_500px {
  min-height: 581px;
}

.float_right_table {
  float: right;
  color: var(--primary-color);
  cursor: pointer;
}

.justify_center {
  justify-content: center;
}

/* .pageNameDiv {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  padding-bottom: 0;
}

.pageNameDiv p {
  margin: 0;
  color: var(--secondary-text);
  font-weight: 500;
}

.pageNameDiv img {
  width: 0.6rem;
} */

.pageNameDiv {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}

.pageNameDiv p {
  margin: 0;
  color: var(--secondary-text);
  font-weight: 500;
  font-size: 14px;
}

.pageNameDiv img {
  width: 0.5rem;
}

.pageCntn_head {
  padding-top: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageCntn_head_left {
  display: flex;
  align-items: center;
}

.redtext {
  color: var(--red-text) !important;
}

.profileImgEdit {
  width: 1rem !important;
  height: 1rem !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: var(--primary-bg);
}

.text_align_center {
  text-align: center;
  justify-content: center;
}

/* -------------------------------------------Website Management Pages Styles--------------------------------------------- */

.newImgInp {
  border: none !important;
  box-shadow: 0px 3.6369197368621826px 3.6369197368621826px 0px #00000040;
}

.newImgInp p {
  color: var(--light-text);
  font-size: 14px;
}

.stallLogo {
  width: 2.5rem !important;
}

.Stall_Img::before {
  background-image: url("../../assets/storefront.png");
}

.tag_line::before {
  background-image: url("../../assets/shoppingmode.svg");
}

.orange_phone::before {
  background-image: url("../../assets/orangePhone.svg");
}

.ornage_email_image::before {
  background-image: url("../../assets/orangeEmail.svg") !important;
}

.width_20px {
  width: 20px;
}

.pagination {
  --bs-pagination-border-width: 0px !important;
  --bs-pagination-color: var(--bs-secondary-color);
}

.tboday {
  user-select: text !important;
}

.guesticon {
  border: none;
  background: transparent;
  margin: auto;
}

.guest_incenterActions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px;
}

.h-90 {
  height: 90% !important;
}

.h-26px {
  height: 26px;
}

.image_show2010 {
  margin: 0px 10px 0px 0px;
  width: 30px;
  border-radius: 50px;
  height: 30px;
}

.image_show5010 {
  margin: 0px 10px 0px 0px;
  width: 100px;
  border-radius: 10px;
  height: 50px;
}

.image_show5050 {
  margin: 0px 10px 0px 0px;
  width: 50px;
  border-radius: 10px;
  height: 50px;
}

.image_show100 {
  width: 100px;
}

.image_show101 {
  width: 100%;
  padding: 1rem;
  padding-bottom: 0;
}

.actionsAlign {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.imgInpBtn {
  border: 1px solid var(--light-black);
  border-radius: 6px;
  padding: 0.2rem 0.8rem;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 15px;
}

.color_primary {
  color: var(--red-text);
}

.productdetail2 {
  display: flow !important;
}

.ReadMsgBody {
  width: 100%;
  background-color: #ffffff;
}

.ExternalClass {
  width: 100%;
  background-color: #ffffff;
}

.visibleMobile {
  display: none;
}

.hiddenMobile {
  display: block;
}

@media only screen and (max-width: 600px) {
  table[class="fullTable"] {
    width: 96% !important;
    clear: both;
  }

  table[class="fullPadding"] {
    width: 85% !important;
    clear: both;
  }

  table[class="col"] {
    width: 45% !important;
  }

  .erase {
    display: none;
  }
}

@media only screen and (max-width: 420px) {
  table[class="fullTable"] {
    width: 100% !important;
    clear: both;
  }

  table[class="fullPadding"] {
    width: 85% !important;
    clear: both;
  }

  table[class="col"] {
    width: 100% !important;
    clear: both;
  }

  table[class="col"] td {
    text-align: left !important;
  }

  .erase {
    display: none;
    font-size: 0;
    max-height: 0;
    line-height: 0;
    padding: 0;
  }

  .visibleMobile {
    display: block !important;
  }

  .hiddenMobile {
    display: none !important;
  }
}

/* --------------------------------------- view product csss---------------------------------------------- */

.productDetails_container span {
  color: var(--blue-grey);
}

.productdetail {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.productdetail p {
  margin: 0;
}

.productHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.productImg {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.productImg2 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.productImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.productImg2 img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.proImg {
  width: 100%;
}

.selectFilter3 {
  width: 100%;
  border: none;
  padding-left: 1rem !important;
  font-size: 16px !important;
  background-color: var(--light-bg) !important;
  outline: none;
}

.backBlue {
  background-color: var(--primary-color) !important;
}

.backBlue p {
  color: var(--white-text) !important;
}

.notFond {
  color: var(--red-text);
  font-weight: 500;
}

.flexCOntauiner {
  display: flex;
  align-items: center;
}

.imgInputCotnainer label {
  cursor: pointer;
}

.imageSizeText {
  font-size: 12px !important;
}
