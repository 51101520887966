.page_content {
  display: flex;
}
.modalzindex {
  z-index: 666;
}
.modal-backdrop {
  z-index: 666 !important;
}
.showOpenBtn {
  display: flex !important;
}

.hideOpneBtn {
  display: none !important;
}

/* .pageCntn_head {
  padding: 0.8rem 1rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageCntn_head_left {
  display: flex;
  align-items: center;
} */

/* .pageNameDiv {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}

.pageNameDiv p {
  margin: 0;
  color: var(--secondary-text);
  font-weight: 500;
}

.pageNameDiv img {
  width: 0.6rem;
} */

.page_body {
  padding: 0.5rem;
}

.page_content_container {
  width: 100%;
  overflow-y: scroll;
  height: 100vh;
}

.pageCntn_head,
.page_body {
  padding-left: 3rem;
  padding-right: 3rem;
}

@media screen and (max-width: 1150px) {
  .pageCntn_head,
  .page_body {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 991px) {
  .pageCntn_head,
  .page_body {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.dashboard {
  height: 100vh;
  overflow: hidden;
}

.addtional_notes_css {
  background-color: var(--notes-back);
  box-shadow: none;
}

.pageCntn_head {
  position: sticky;
  top: 72px;
  background-color: var(--primary-bg);
  z-index: 91;
  padding-bottom: 0.5rem !important;
}
